export const environment = {
  production: false,

  API_DOMAINS: {
    // new coso prod
    // LOGIN: 'https://api.serhafen.tech/auth',
    // USERS: 'https://api.serhafen.tech/users',
    // MAWB: 'https://api.serhafen.tech/awb',
    // AWB_HEADER: 'https://api.serhafen.tech/awb/header',
    // MASTER_FLIGHTS: 'https://api.serhafen.tech/flights',
    // TRUCK_TRAVEL: 'https://api.serhafen.tech/truck/travel',
    // TRUCKS: 'https://api.serhafen.tech/truck/travel/trucks',
    // COMPANY: 'https://api.serhafen.tech/truck/travel/companies',
    // TYPE_TRAVEL: 'https://api.serhafen.tech/truck/travel/type',
    // FLIGHTS: 'https://api.serhafen.tech/flights',
    // MAWB_DETAIL: 'https://api.serhafen.tech/mawb',
    // AWB_ROUTE: 'https://api.serhafen.tech/awb/detail/route',
    // FLOW: 'https://api.serhafen.tech/flow',
    // STATUS_CODES: 'https://api.serhafen.tech/status-code',
    // KEY_WORDS: 'https://api.serhafen.tech/key-word',
    // EXTERNAL: 'https://api.serhafen.tech/external',
    // RECEIVED_MESSAGES: 'https://api.serhafen.tech/received-messages',
    // COUNTRY: 'https://api.serhafen.tech/country',
    // NEWS: 'https://api.serhafen.tech/news',
    // ENV: 'LOCAL',

  //S3 QA
  LOGIN: 'https://api.serhafen-dev.tech/auth',
  USERS: 'https://api.serhafen-dev.tech/users',
  MAWB: 'https://api.serhafen-dev.tech/awb',
  AWB_HEADER: 'https://api.serhafen-dev.tech/awb/header',
  MASTER_FLIGHTS: 'https://api.serhafen-dev.tech/flights',
  TRUCK_TRAVEL: 'https://api.serhafen-dev.tech/truck/travel',
  TRUCKS: 'https://api.serhafen-dev.tech/truck/travel/trucks',
  COMPANY: 'https://api.serhafen-dev.tech/truck/travel/companies',
  TYPE_TRAVEL: 'https://api.serhafen-dev.tech/truck/travel/type',
  FLIGHTS: 'https://api.serhafen-dev.tech/flights',
  MAWB_DETAIL: 'https://api.serhafen-dev.tech/mawb',
  AWB_ROUTE: 'https://api.serhafen-dev.tech/awb/detail/route',
  FLOW: 'https://api.serhafen-dev.tech/flow',
  STATUS_CODES: 'https://api.serhafen-dev.tech/status-code',
  KEY_WORDS: 'https://api.serhafen-dev.tech/key-word',
  EXTERNAL: 'https://api.serhafen-dev.tech/external',
  RECEIVED_MESSAGES: 'https://api.serhafen-dev.tech/received-messages',
  COUNTRY: 'https://api.serhafen-dev.tech/country',
  NEWS: 'https://api.serhafen-dev.tech/news',
  ITINERARY: 'https://api.serhafen-dev.tech/itinerary',
  PRE_ALERT: 'https://api.serhafen-dev.tech/pre/alert',
  GOVERNMENT_ADDRESS: 'https://api.serhafen-dev.tech/government-address',
  ENV: 'LOCAL',

// ----- SERVER LOCAL -----
    // LOGIN: 'http://localhost:3000/auth',
    // USERS: 'http://localhost:3000/users',
    // MAWB: 'http://localhost:3000/awb',
    // AWB_HEADER: 'http://localhost:3000/awb/header',
    // MASTER_FLIGHTS: 'http://localhost:3000/flights',
    // TRUCK_TRAVEL: 'http://localhost:3000/truck/travel',
    // TRUCKS: 'http://localhost:3000/truck/travel/trucks',
    // COMPANY: 'http://localhost:3000/truck/travel/companies',
    // TYPE_TRAVEL: 'http://localhost:3000/truck/travel/type',
    // FLIGHTS: 'http://localhost:3000/flights',
    // MAWB_DETAIL: 'http://localhost:3000/mawb',
    // AWB_ROUTE: 'http://localhost:3000/awb/detail/route',
    // FLOW: 'http://localhost:3000/flow',
    // STATUS_CODES: 'http://localhost:3000/status-code',
    // KEY_WORDS: 'http://localhost:3000/key-word',
    // EXTERNAL: 'http://localhost:3000/external',
    // RECEIVED_MESSAGES: 'http://localhost:3000/received-messages',
    // COUNTRY: 'http://localhost:3000/country',
    // NEWS: 'http://localhost:3000/news',
    // ITINERARY: 'http://localhost:3000/itinerary',
    // PRE_ALERT: 'http://localhost:3000/pre/alert',
    // GOVERNMENT_ADDRESS: 'http://localhost:3000/government-address',
    // ENV: 'LOCAL',
  },
  GLOBAL_SETTINGS: {
    MESSAGE_LOADING_INFORMATION: 'Cargando información, por favor espere un momento ...',
    MESSAGE_PROCESSING_INFORMATION: 'Procesando información, por favor espere un momento ...',
    GENERATING_DOCUMENT: 'Generando documento, por favor espere un momento ...',
  },
  TYPE: 'pc',
};
